<template>
  <div class="probe document-view">
    <document-generator />
    <div class="jilu">
      <p class="title ft-bold">星耀报告</p>
      <p>
        <span>上次生成时间：{{ date }}</span><button class="refreshBtn ft" size="small" @click="$emit('refresh-tab')">
          刷新报告
        </button>
        <span>￥{{ price }}/次</span>
      </p>
    </div>
    <div>
      <div><span>报告编号：{{ data.reportNo }}</span></div>
      <el-divider />
      <!--基本信息-->
      <diV class="info">
        <div style="text-align: center;flex: 1"><img style="height: 19vh;" src="@/assets/img/report/info.png" /></div>
        <div style="flex: 1;">
          <div class="pd base"><span class="name">{{ data.base_info.name }}</span><span class="radius mg">年龄：{{
            data.base_info.age }}</span><span class="radius mg">性别：{{ data.base_info.gender }}</span></div>
          <div class="pd"><span class="fb">手机号：</span>{{ data.base_info.phone }}</div>
          <div class="pd"><span class="fb">身份证号码：</span>{{ data.base_info.ident_number }}</div>
        </div>
        <div style="flex: 1;">
          <div class="hidden-xs-only" style="line-height: 68px;">&nbsp;</div>
          <div class="pd"><span class="fb">号码归属地：</span>{{ data.base_info.phone_address }}</div>
          <div class="pd"><span class="fb">户籍：</span>{{ data.base_info.ident_number_address }}</div>
        </div>
      </diV>
      <!--风险建议-->
      <div class="fb">风险建议：<span :style="`color:${recommentFilter(data.cphs.verify_recomment)}`">{{
        data.cphs.verify_recomment }}</span></div>
      <!--评分-->
      <div class="flex-c hidden-xs-only">
        <div class="flex-i" v-for="(it, index) in scores" :key="index">
          <span :class="it.selected ? 'tip_box_left lh-60' : 'tip_box_left_t lh-60'">{{
            it.selected ? data.cphs.score_norm_explain : '' }}</span>
          <div class="score" :style="`background-color: ${it.color};`">{{ it.label }}</div>
          <el-row class="mt-10">
            <el-col class="tal" :span="12"><span>{{ it.start }}</span></el-col>
            <el-col class="tar" v-if="it.end == 450" :span="12"><span>{{ it.end }}</span></el-col>
          </el-row>
        </div>
      </div>
      <!--手机端显示-->
      <div class="flex-c hidden-lg-and-up">
        <div class="flex-i" v-for="(it, index) in scores" :key="index">
          <span :class="it.selected ? 'tip_box_left lh-60' : 'tip_box_left_t lh-60'">{{
            it.selected ? data.cphs.score_norm_explain : '' }}</span>
          <div class="score" :style="`background-color: ${it.color};`">{{ it.short }}</div>
          <el-row class="mt-10">
            <el-col class="tal" :span="12"><span>{{ it.start }}</span></el-col>
            <el-col class="tar" v-if="it.end == 450" :span="12"><span>{{ it.end }}</span></el-col>
          </el-row>
        </div>
      </div>
      <!--群体统计逾期率-->
      <div class="mt-20">群体统计逾期率预测：需要填写{{ }}</div>
      <div class="mt-20">注:分值在【450，750】之间，得分越低，风险越高:【450,535)建议拒绝，【535,615)建议审核，【615,750】建议通过。</div>
      <!--风险标注-->
      <div class="mt-20 fb">风险标注</div>
      <el-table class="mt-10" :data="data.cphs.hit_risk_tagging" :border="true">
        <el-table-column prop="key" label="序号">
        </el-table-column>
        <el-table-column prop="label" label="风险点">
        </el-table-column>
      </el-table>
      <!--身份信息验核-->
      <div class="mt-20 fb">身份信息验核</div>
      <div class="mt-10 ft" style="display: flex;">
        <div style="flex: 1;" class="ta-c bg-label lh-10 bordered">身份证信息是否一致</div>
        <div style="flex: 1;" class="ta-c lh-10 bordered">{{ data.ident_info_dict.identity_two_elements }}</div>
      </div>
      <!--运营商信息-->
      <div class="mt-20 fb">运营商信息</div>
      <el-table class="mt-10" :data="data.ident_info_dict.operatorList" :border="true">
        <el-table-column prop="network_triple_elements" label="运营商校验" />
        <el-table-column prop="time_online" label="在网时长" />
        <el-table-column prop="phone_status" label="在网状态" />
        <el-table-column prop="operator_type" label="所属运营商" />
      </el-table>
      <!--群体统计逾期率-->
      <div class="mt-20 fb">宝系健康度：{{ data.zfb_level?.level }}</div>
      <div class="mt-20">注:宝系健康度共分为A/B/C/D/E5个等级，A等级最高，表示用户质量最好，E等级最差，表示用户质量最差，--表示未查得。</div>
      <!--不良行为检测-->
      <div class="mt-20 fb">不良行为检测</div>
      <el-table class="mt-10" :data="data.focus_list_check.list" :border="true">
        <el-table-column v-for="(value, key) in data.focus_list_check.list[0]" :key="key" :label="value.label">
          <template slot-scope="scope">
            <span :style="{ color: scope.row[value.key].color }">{{ scope.row[key].value }}</span>
          </template>
        </el-table-column>
      </el-table>
      <!--风险名单检测-->
      <div class="mt-20 fb">风险名单检测</div>
      <el-table class="mt-10" :data="data.risk_list_check.list" :border="true">
        <el-table-column v-for="(value, key) in data.risk_list_check.list[0]" :key="key" :label="value.label">
          <template slot-scope="scope">
            <span :style="{ color: scope.row[value.key].color }">{{ scope.row[key].value }}</span>
          </template>
        </el-table-column>
      </el-table>
      <!--网贷行为评估-->
      <div class="mt-20 fb">网贷行为评估</div>
      <radar-block title="注册记录" />
      <div class="row ft">
        <diV class="item" v-for="(value, index) in data.register_history.infoList" :key="index">
          <diV class="child">
            <div class="label">{{ value.label }}</div>
            <div class="value">{{ value.value }}</div>
          </diV>
        </diV>
      </div>
      <el-table class="mt-10" :data="data.register_history.countList" :border="true">
        <el-table-column v-for="(value, key) in data.register_history.countList[0]" :key="key" :label="value.label">
          <template slot-scope="scope">
            <span>{{ scope.row[key].value }}</span>
          </template>
        </el-table-column>
      </el-table>
      <radar-block title="注册记录" />
      <el-table class="mt-10" :data="data.personal_loan_demand.list" :border="true">
        <el-table-column v-for="(value, key) in data.personal_loan_demand.headers" :key="key" :label="value.label">
          <template slot-scope="scope">
            <span>{{ scope.row[value.key] }}</span>
          </template>
        </el-table-column>
      </el-table>

      <radar-block title="申请记录" />
      <div class="apply ft">
        <div class="label">
          <div class="content">近12个月申请机构总数</div>
        </div>
        <div class="value">{{ data.personal_loan_s.apply_mechanism_number }}</div>
      </div>
      <div class="row ft">
        <diV class="item" v-for="(value, index) in data.personal_loan_s.infoList" :key="index">
          <diV class="child">
            <div class="label">{{ value.label }}</div>
            <div class="value">{{ value.value }}</div>
          </diV>
        </diV>
      </div>
      <el-table class="mt-10" :data="data.personal_loan_s.countList" :border="true">
        <el-table-column v-for="(value, key) in data.personal_loan_s.countList[0]" :key="key" :label="value.label">
          <template slot-scope="scope">
            <span>{{ scope.row[value.key].value }}</span>
          </template>
        </el-table-column>
      </el-table>

      <radar-block title="放款记录" />
      <div class="apply ft">
        <div class="label">
          <div class="content">近24个月放款机构总数</div>
        </div>
        <div class="value">{{ data.personal_loan_f.lenders }}</div>
      </div>
      <div class="row ft">
        <diV class="item" v-for="(value, index) in data.personal_loan_f.infoList" :key="index">
          <diV class="child">
            <div class="label">{{ value.label }}</div>
            <div class="value">{{ value.value }}</div>
          </diV>
        </diV>
      </div>
      <el-table class="mt-10" :data="data.personal_loan_f.list" :border="true">
        <el-table-column v-for="(value, key) in data.personal_loan_f.headers" :key="key" :label="value.label">
          <template slot-scope="scope">
            <span>{{ scope.row[value.key] }}</span>
          </template>
        </el-table-column>
      </el-table>


      <radar-block title="还款记录" />
      <el-table class="mt-10" :data="data.personal_loan_h.list" :border="true">
        <el-table-column v-for="(value, key) in data.personal_loan_h.headers" :key="key" :label="value.label">
          <template slot-scope="scope">
            <span>{{ scope.row[value.key] }}</span>
          </template>
        </el-table-column>
      </el-table>

      <radar-block title="历史逾期记录" />
      <div class="row ft">
        <diV class="item" v-for="(value, index) in data.personal_overdue_history.infoList" :key="index">
          <diV class="child">
            <div class="label">{{ value.label }}</div>
            <div class="value">{{ value.value }}</div>
          </diV>
        </diV>
      </div>
      <el-table class="mt-10" :data="data.personal_overdue_history.datalist" :border="true">
        <el-table-column v-for="(value, key) in data.personal_overdue_history.headers" :key="key" :label="value.label">
          <template slot-scope="scope">
            <span>{{ scope.row[value.key] }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="ft mt-10">注:逾期时长，S/M表示逾期的期数。S代表现金贷的期数，1期=7天</div>
      <div class="ft">S0表示不到7天、S1代表7-14天，以此类推。</div>
      <div class="ft">M代表消费分期的期数，1期=30天，M0表示不到30天，M1代表30-60天，以此类推。</div>

      <!--租赁申请行为-->
      <div class="mt-20 fb">租赁申请行为</div>
      <el-table class="mt-10" :data="data.rent_history.list" :border="true">
        <el-table-column v-for="(value, key) in data.rent_history.headers" :key="key" :label="value.label">
          <template slot-scope="scope">
            <span>{{ scope.row[value.key] }}</span>
          </template>
        </el-table-column>
      </el-table>
      <el-table class="mt-10" :data="data.rent_history.linkList" :border="true">
        <el-table-column v-for="(value, key) in data.rent_history.linkList[0]" :key="key" :label="value.label">
          <template slot-scope="scope">
            <span>{{ scope.row[key].value }}</span>
          </template>
        </el-table-column>
      </el-table>

      <!--关联风险检测-->
      <div class="mt-20 fb">关联风险检测</div>
      <el-table class="mt-10" :data="data.relevance_risk_check.list" :border="true">
        <el-table-column v-for="(value, key) in data.relevance_risk_check.list[0]" :key="key" :label="value.label">
          <template slot-scope="scope">
            <span>{{ scope.row[key].value }}</span>
          </template>
        </el-table-column>
      </el-table>

      <!--法院风险信息-->
      <div class="mt-20 fb">法院风险信息</div>
      <el-table class="mt-10" :data="data.court_risk_info_list" :border="true">
        <el-table-column v-for="(value, key) in data.court_risk_info_list_headers" :key="key" :label="value.label">
          <template slot-scope="scope">
            <span>{{ scope.row[value.key] }}</span>
          </template>
        </el-table-column>
      </el-table>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex"
import getReports from "@/mixins/getReports"
import DocumentGenerator from "@/components/viewBar/reports/document-generator"
import radarBlock from "./radar-block"

export default {
  mixins: [getReports],
  components: { DocumentGenerator, radarBlock },
  computed: {
    ...mapGetters("userReport/starlight", ["data"]),
    ...mapState("userReport", ["uid", "price"]),
    ...mapState("userReport/starlight", ["date"]),
  },
  data() {
    return {
      scores: [{ label: '极低风险', short: '极低', color: '#67C23A', start: 750, end: 615, selected: false },
      { label: '低风险', short: '低', color: '#65f2eb', start: 615, end: 585, selected: false },
      { label: '中风险', short: '中', color: '#409EFF', start: 585, end: 560, selected: false },
      { label: '高风险', short: '高', color: '#E6A23C', start: 560, end: 535, selected: false },
      { label: '极高风险', short: '极高', color: '#F56C6C', start: 535, end: 450, selected: false }
      ]
    }
  },
  created() {
    this.load()
  },
  methods: {
    ...mapActions("userReport/starlight", ["getStarlight"]),
    load(refresh = false) {
      this.getStarlight(refresh).then((res) => {
        if (res && this.getReports()) {
          if (this.data && this.data.cphs) {
            this.scoreFilter(this.data.cphs.score_norm_explain)
          }
        }
      });
    },
    recommentFilter(val) {
      switch (val) {
        case '建议拒绝':
          return '#F56C6C'
        case '建议审核':
          return '#409EFF'
        case '建议通过':
          return '#67C23A'
        default:
          return '#E6A23C'
      }
    },
    scoreFilter(val) {
      this.scores.map(it => {
        if (val < it.start && val > it.end) {
          it.selected = true
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.probe {
  background-color: #fff;
  padding: 20px 22px 50px;
  position: relative;

  .with-margin {
    margin-bottom: 10px;
  }

  .jilu {
    width: 100%;
    height: 114px;
    background: #ffffff;
    padding: 20px 0;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 10px;
    position: relative;
    z-index: 999;

    .title {
      font-size: 24px;
      color: #000000;
    }

    .refreshBtn {
      background-color: #f56c6c;
      padding: 3px 5px;
      color: #ffffff;
      border: none;
      border-radius: 3px;
      cursor: pointer;
      margin: 0 10px;
    }
  }
}


.info {
  display: flex;
  flex-wrap: wrap;
  column-gap: 5vw;
  margin-bottom: 2vh;

  .mg {
    margin-left: 20px;
  }

  .pd {
    padding: 10px 0px 10px 0px;
  }

  .base {
    display: flex;
    align-items: center;

    .name {
      font-weight: bold;
      font-size: 2em;
    }

  }

  .radius {
    text-align: center;
    display: inline-block;
    padding: 5px 0 5px 0;
    width: 80px;
    border: 1px solid #718acf;
    border-radius: 30px;
  }
}

@media (max-width: 768px) {
  .info {
    flex-direction: column;
    /* 改变为主轴方向为垂直 */
  }
  .score {
    margin-top: 0 !important;
    color: #f56c6c;
  }
  .item {
    flex: 1 0 100% !important;
  }
  .apply {
    .value {
      flex: 1 !important;
    }
  }
}



.score {
  line-height: 50px;
  margin-top: 1vh;
  color: #ffffff;
}

.flex-c {
  display: flex;
}

.flex-c .flex-i {
  flex: 1;
  text-align: center;
}

.lh-60 {
  line-height: 60px;
}

.tal {
  text-align: left;
}

.tar {
  text-align: right;
}

::v-deep .el-table th,
::v-deep .el-table td {
  text-align: center;
}

::v-deep .el-table tr th {
  background-color: rgb(222 228 235);
}

::v-deep .el-table thead {
  color: black;
  font-weight: 500;
}

.bg-label {
  background-color: rgb(222 228 235);
}

.row {
  display: flex;
  flex-wrap: wrap;
  /* 允许换行 */
  line-height: 45px;
}

.item {
  flex: 1 0 50%;
  /* 每个项目占据容器宽度的50%，但允许增长以填满剩余空间 */
  box-sizing: border-box;
  /* 确保padding和border被包含在元素的总宽度内 */
  margin: -1px;
  /* 负边距以抵消伪元素的边框 */
  position: relative;
  /* 使伪元素相对于父元素定位 */
  background-color: white;

  /* 可选：背景颜色 */
  .child {
    display: flex;

    .label {
      flex: 0 0 50%;
      background-color: rgb(222 228 235);
      text-align: center;
    }

    .value {
      flex: 0 0 50%;
      text-align: center;
    }
  }
}

.item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #ccc;
  /* 添加边框 */
  pointer-events: none;
  /* 确保伪元素不影响鼠标事件 */
}

.single-item {
  flex-basis: 100%;

  /* 可选：背景颜色 */
  .child {
    display: flex;

    .label {
      flex: 0 0 50%;
      background-color: rgb(222 228 235);
      padding-left: 20px;
    }

    .value {
      flex: 0 0 50%;
      text-align: center;
    }
  }
}

.apply {
  display: flex;
  border: 1px solid #ccc;
  line-height: 45px;
  justify-content: space-between;
  margin: -1px;
  text-align: center;

  .label {
    flex: 1;
    background-color: rgb(222 228 235);
    box-sizing: border-box;
  }

  .value {
    flex: 3;
    text-align: center;
  }
}
</style>
